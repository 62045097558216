
import React, { useState, useEffect } from "react";
import { Link } from "@/helpers/common_helper";
import { menu } from "@/config/site"
import { escapeRegExp, isEmpty, map, startsWith } from "lodash";
import { useRouter } from "next/router";
import { Image, Button, Select, NativeSelect } from "@mantine/core";
import { removeCookies } from 'cookies-next';

export default function Index({ session, t, ...props }) {
    const [isDropdownOpen, setIsDropdownActive] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const router = useRouter();
    const { locale, pathname, asPath, query } = router;
    const [language, setLanguage] = useState(locale);

    const toggleDropdown = () => setIsDropdownActive(!isDropdownOpen);

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
        setIsDropdownActive(false); // Optionally close the dropdown when opening settings
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.currentTarget.value);
    };

    const _done = async () => {
        if (language !== t("lang")) {
            const currentFullPath = window.location.pathname + window.location.search;
            const newPath = currentFullPath.replace(new RegExp(`/${t("lang")}`), `/${language}`);
            window.location.href = window.location.origin + newPath;
        }
        setIsSettingsOpen(false);
    };
    

    return <>
        {/* <!-- Navbar start here --> */}
        <div className="home_headernav small-none desktop col-auto">
            <div className="logo">
                <a href={"/" + router.locale + "/"}><Image
                    w={200}
                    h={40}
                    src="/assets/images/logo/logo.jpg"
                    alt="site"
                /></a>
            </div>
            <ul className="nav flex-column">
                {map(menu.desktop, (value, key) => {
                    if (key == "/") {
                        return <li className="nav-item" key={key}>
                            <a href={"/" + router.locale + "/"} className={router.asPath == key || (key != "/" && startsWith(router.asPath, key)) ? "active" : ""}>
                                <img
                                    src={value.img}
                                    alt="site" />
                                <span className="fs-16">{t(value.label)}</span></a>
                        </li>
                    } else {
                        return <li className="nav-item" key={key}>
                            <Link href={key} locale={router.locale}>
                                <a className={router.asPath == key || (key != "/" && startsWith(router.asPath, key)) ? "active" : ""}>
                                    <img
                                        src={value.img}
                                        alt="site" />
                                    <span className="fs-16">{t(value.label)}</span></a>
                            </Link>
                        </li>
                    }
                })}
                <li className="nav-item">
                    {
                        isEmpty(session.user) ?
                            <Link href={"/sign-in?redirect=" + encodeURIComponent(router.asPath)}>
                                <a className={router.asPath == "/sign-in" ? "active" : ""}>
                                    <img
                                        src={"/assets/images/icons/menu-profile-icon.svg"}
                                        alt="site" />{t("Sign In")}</a>
                            </Link> :
                            <Link href={"/profile"}>
                                <a className={router.asPath == "/profile" ? "active" : ""}>
                                    <img
                                        src={"/assets/images/icons/menu-profile-icon.svg"}
                                        alt="site" />{t("Profile")}</a>
                            </Link>
                    }
                </li>
            </ul>

            <div className={`nav-more ${isDropdownOpen ? "active" : ""}`} onClick={toggleDropdown}>
                <img src="/assets/images/icons/more.svg" alt="More" style={{ marginRight: '8px' }} />
                <span>{t("More")}</span>
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <ul>
                            <li><Link href="/about-us"><a><img src="/assets/images/icons/about-us.svg" alt="About Us" />{t("about_us")}</a></Link></li>
                            <li><Link href="/terms-of-service"><a><img src="/assets/images/icons/terms-of-service.svg" alt="Terms of Service" />{t("terms_of_service")}</a></Link></li>
                            <li><Link href="/privacy-policy"><a><img src="/assets/images/icons/privacy-policy.svg" alt="Privacy Policy" />{t("Privacy Policy")}</a></Link></li>
                            <li><a onClick={toggleSettings}><img src="/assets/images/icons/setting.png" alt="Settings" />{t("Setting")}</a></li>
                            {
                                session.user && <li><a
                                    onClick={() => {
                                        removeCookies("user");
                                        window.location = "/"
                                    }}
                                    className="cursor-pointer" style={{
                                        borderTop: "1px solid #acacaa"
                                    }}>{t("Log Out")}</a></li>
                            }
                        </ul>
                    </div>
                )}
            </div>
        </div>
        {isSettingsOpen && (
            <div className="settings-container">
                <h4>{t("Settings")}</h4>
                <div className='text-start account-setting-label mb-3'>{t("Language")}</div>
                <NativeSelect
                    value={language}
                    onChange={handleLanguageChange}
                    data={[
                        { value: 'en', label: 'English' },
                        { value: 'zh', label: 'Chinese' },
                    ]}
                />
                <div className="my-5 d-grid">
                    <Button
                        className="btn btn-primary signup-button"
                        onClick={() => _done()}>
                        {t("Done")}
                    </Button>
                </div>
            </div>
        )}
    </>
}

export async function getServerSideProps({ locale, }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common'])),
        },
    };
}

